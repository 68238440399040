<template>
<a-card :bordered="false">
    <div class="realtor">
        <!-- 查询表单 -->
      <a-form-model ref="searchForm" layout="horizontal" :label-col="{xs:6}" :wrapper-col="{xs:18}">
        <a-row :gutter="8">
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="大区事务所">
              <a-cascader
                :options="dealerList"
                v-model="regionalOffice"
                change-on-select
                placeholder="请选择大区/事务所"
                @change="onChange"
                :field-names="{ label: 'title', value: 'id', children: 'children' }"
              />
            </a-form-model-item>
          </a-col>

          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="经销商">
              <a-input v-model="searchData.dealerName" placeholder="请输入经销商名称" allowClear></a-input>
            </a-form-model-item>
          </a-col>

          <a-col :xxl="6" :xl="5" :md="8" sm="2">
            <a-form-model-item :label-col="{span: 0}" :wrapper-col="{span:24}">
              <a-button type="primary" @click="getData()" icon="search">查询</a-button>
              <a-button type="default" @click=" reset()" icon="sync">重置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
        <div>
            <!-- <a-button type="primary" @click="toHandler('add')">新增</a-button> -->
            <a-button v-if="isEdit" type="primary" @click="toHandler('edit')">修改积分</a-button>
            <a-button v-if="isCheck" type="primary" @click="toHandler('check')">查看积分明细</a-button>
<!--            <a-upload-->
<!--              name="file"-->
<!--              :action="uploadUrl"-->
<!--              :headers="headers"-->
<!--              :showUploadList="false"-->
<!--              @change="uploadChange"-->
<!--            >-->
<!--              <a-button v-if="isImportOld" icon="upload" type="default">导入旧系统积分</a-button>-->
<!--            </a-upload>-->
            <downLoad v-if="isExport" method="get" api="/api/dealer/dealer/dealerPayInfo/excelDealerIntegral" :params="searchData" name="经销商积分一览.xls">导出积分信息</downLoad>
            <!-- <a-button type="primary" @click="toHandler('del')">删除</a-button> -->
        </div>
        <!-- 数据表格 -->
        <a-table :loading="tableLoading" :row-selection="rowSelection" :rowKey="record => record.id"
                 :columns="columns" :data-source="tableData" bordered :pagination="page"
                 @change="changeTable" :customRow="changeTableRow" :rowClassName="rowClassName">
        </a-table>
        <!-- 编辑表单 -->
        <CustomerPayInfo-edit-modal ref="CustomerPayInfoEditModal" @reload="getData"></CustomerPayInfo-edit-modal>
        <IntegralDetail ref="IntegralDetail"></IntegralDetail>
    </div>
</a-card>

</template>

<script>
import { columns } from './components/colums.js'
import CustomerPayInfoEditModal from './components/CustomerPayInfoEditModal.vue'
import IntegralDetail from './components/IntegralDetail.vue'
import {
  delCustomerPayInfo,
  listCustomerPayInfo,
  listOnlyIntegral,
  selectByIdCustomerPayInfo
} from './api/CustomerPayInfoApi'
import {checkPermission} from "@/utils/permissions";
import {baseURL} from '@/utils/request';
import {DD_DEALER_TEMP} from '@/utils/downloadTempHref';

const pageSource = {
    current: 1,
    pageSize: 10,
    total: 0
}
export default {
    name: 'customerPayInfo',
    components: {
        CustomerPayInfoEditModal,
        IntegralDetail
    },
    data() {
        return {
            columns: columns,
            tableData: [],
            searchData: {}, // 搜索条件
            scroll:{ y: 600 },
            tableLoading: false, //表格loading
            dealerList:[],
            regionalOffice: [],
            page: {
                current: 1,
                pageSize: 10,
                total: 0,
                showSizeChanger: true,
                showTotal: () => `共${this.page.total}条`,
            },
            //上传文件地址
            uploadUrl: baseURL + '/api/dealer/dealer/dealerIntegralRecord/importOld',
            //上传文件请求头
            headers: {
              'Authorization-Admin': this.$store.getters.token,
            },
            isEdit: checkPermission('customer:pay:info:edit'),
            isCheck: checkPermission('customer:pay:info:check'),
            selectedRowKeys: [],
            selectedRows: [],
            isImportOld: checkPermission('customer:pay:info:importOld'),
            isExport: checkPermission('customer:pay:info:export'),
        }
    },
    computed: {
        rowSelection() {
            const _this = this
            return {
                fixed: true,
                type: 'radio', //"radio"
                selectedRowKeys: this.selectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
                    _this.selectedRowKeys = selectedRowKeys
                    _this.selectedRows = selectedRows
                }
            }
        }
    },
    methods:{
      uploadChange (info) {
        this.tableLoading = true
        setTimeout(()=>{
          if(info.file.response){
            let hint = info.file.response
            if(hint.code === 200) {
              this.tableLoading = false
              this.$message.success(hint.message)
              this.getData()
            }else if(hint.code === 500) {
              this.tableLoading = false
              this.$message.error(hint.message)
            }
            if (info.file.status !== 'uploading') {
              console.log(info.file, info.fileList)
            }
          }
        })

      },
        // 隔行换色
        rowClassName(record,index) {
            let className = "light-row"
            if (index % 2 === 1) className = "dark-row"
            return className;
        },
        /**
         * 获取表格数据
         */
        getData() {
            this.tableLoading = true
            listOnlyIntegral({
                pageNumber: this.page.current,
                pageSize: this.page.pageSize,
                ...this.searchData
            }).then(res => {
                if (res.code === 200) {
                    const { total, records } = res.body
                    this.page.total = total
                    this.tableData = records
                }
            }).finally(() => this.tableLoading = false)

          this.axios.post('/api/firm/mhiac/mhiacFirm/getTree').then((res) => {
            // 获取大区事务所经销商数据
            this.dealerList = res.body
          })
        },
        /**
         * 筛选查询
         */
        getDataFilter() {
            this.page.current = 1;
            this.getData()
        },
        /**
         * 重置
         */
        reset() {
            this.searchData = {}
            this.regionalOffice = []
            this.page.current = 1;
            this.page.pageSize = 10
            this.getData()
        },
        /**
         * 更改分页
         */
        changeTable(pagination) {
            this.page = pagination
            this.getData()
        },
        /**
         * 点击行选中
         */
        changeTableRow(record) {
            return {
                on: {
                    click: e => {
                        console.log(e)
                        if (this.selectedRowKeys[0] === record.id) {
                            this.selectedRowKeys = []
                            this.selectedRows = []
                        } else {
                            this.selectedRowKeys = [record.id]
                            this.selectedRows = [record]
                        }
                    }
                }
            }
        },

        onChange(val) {
          this.searchData.areaId = val[0]
          this.searchData.firmId = val[1]
        },

        toHandler(name) {
            const _this = this
            if (name === 'add') {
                _this.$refs.DemandEditModal.setRowData({}, 'add')
                return
            }
            if (_this.selectedRows.length === 0) {
                _this.$notification.warning({
                  message: '请选择一条记录'
                })
                return
            }
            switch (name) {
                case 'edit':
                    _this.$refs.CustomerPayInfoEditModal.setRowData(_this.selectedRows[0], 'edit')
                    break
                case 'del':
                    _this.$confirm({
                        title: '删除警告',
                        content: '确认要删除此条记录吗?',
                        okText: '确认',
                        cancelText: '取消',
                        async onOk() {
                            const res = await delCustomerPayInfo(_this.selectedRows[0].id)
                            if (res.code === 200) {
                                _this.$notification.success({ message: res.message })
                                this.getData()
                            } else {
                                _this.$notification.error({ message: res.message })
                            }
                        },
                        onCancel() {}
                    })
                    break
                case 'check':
                    _this.$refs.IntegralDetail.isShow(_this.selectedRows[0], 'check')
                    break
            }
        },
    },
    created() {
        this.getData()
    }
}
</script>

<style lang="less" scoped>
    .ant-btn {
        margin-right: 8px;
        margin-bottom: 12px;
    }
    ::v-deep .ant-table-tbody > .light-row {
        background: #FFF!important;;
    }
    ::v-deep .ant-table-tbody > .dark-row {
        background: #fafafa!important;
    }
</style>
